import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import ChristmasHat from '@/public/icons/logo/christmas_hat.png';
import * as Icons from './Icons';

export default function AccessBarLogo() {
  // TODO: This isn't going to work in 2024 ;)
  const isHolidaysSeason =
    dayjs().isBefore('2023-12-28', 'day') &&
    dayjs().isAfter('2023-11-30', 'day');

  return (
    <>
      <div className="mt-2 hidden hover:cursor-pointer lg:mr-10 lg:flex">
        <Link href="/" prefetch={false} legacyBehavior>
          <a
            aria-label="Link to Home"
            id="icon-home-desktop"
            className="relative"
          >
            {isHolidaysSeason && (
              <span className="absolute -top-2 -left-2 h-[35px]">
                <Image
                  src={ChristmasHat}
                  alt="Reebelo Christmas"
                  className="h-full w-full cursor-pointer object-cover"
                  priority
                />
              </span>
            )}
            <Icons.Logo />
          </a>
        </Link>
      </div>

      <div className="flex items-center justify-center hover:cursor-pointer lg:hidden">
        <Link href="/" prefetch={false} legacyBehavior>
          <a
            aria-label="Link to Home"
            id="icon-home-mobile"
            className="relative"
          >
            {isHolidaysSeason && (
              <span className="absolute top-0 -right-2 h-[25px] scale-x-[-1] scale-y-[1]">
                <Image
                  src={ChristmasHat}
                  alt="Reebelo Christmas"
                  className="h-full w-full cursor-pointer object-cover"
                  priority
                />
              </span>
            )}
            <Icons.LogoMobile />
          </a>
        </Link>
      </div>
    </>
  );
}
