import Link from 'next/link';
import cn from 'classnames';
import { MenuItemProps } from './types';
import { generateCategoryPageUrl } from '@/components/category/helpers';

const MenuItem = ({ handle, title, pk, index }: MenuItemProps) => {
  const link = handle
    ? `/collections/${handle}`
    : generateCategoryPageUrl(title, pk as string);

  return (
    <li
      key={handle}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="menu-item relative"
    >
      <Link href={link} prefetch={false}>
        <a
          className={cn(
            'flex w-full px-3 py-4  hover:[text-shadow:0.5px_0px_0px_#000]',
            {
              'pl-0': index === 0,
            },
          )}
        >
          <span className="whitespace-nowrap">{title}</span>
        </a>
      </Link>
    </li>
  );
};

export default MenuItem;
