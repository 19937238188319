import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import Loading from '@lambda/ui/src/Loading';
import settings, { priceFormater } from '@/settings';
import i18n from '@/settings/i18n';
import { getOfferUri } from '@/lib/offer';
import GTM from '@/lib/google-tag-manager';
import type { SearchResponse } from '@/api/search';
import { trackProductsSearched } from '@/lib/analytics/trackProduct';

const t = i18n.init();

export default function SearchOutput(
  props: PropsWithChildren<{
    search: string;
    className?: string;
    toggle?: () => void;
    results: SearchResponse | null;
    setResults: Dispatch<SetStateAction<SearchResponse | null>>;
    show: boolean;
    onOutsideClick: () => void;
  }>,
) {
  const router = useRouter();
  const { results, setResults, search, show } = props;
  const response = useSWR<SearchResponse>(() =>
    search ? `/api/search?q=${search}` : null,
  );

  useEffect(() => {
    // Make the modal sticky
    if (response.data != null) setResults(response.data);
  }, [response.data]);

  const emptyOffersResult = results == null || results.offers?.length === 0;
  const emptyCollectionsResult =
    results == null || results.collections?.length === 0;
  const getSmallShopifyImage = (imageSrc?: string) =>
    imageSrc?.replace(/(\w)(\.\w{3}\?)/, '$1_60x$2');

  const trackSearchSubmitted = () => {
    if (search !== '' && results != null) trackProductsSearched(search);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      )
        props.onOutsideClick();
    };

    // Attach the event listener to the document when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [props.onOutsideClick]);

  const shouldShowPrice = !['reebelo-us', 'reebelo-ca'].includes(
    settings.store,
  );

  // Popper
  return (
    <div className={cn('relative w-full', props.className)} ref={wrapperRef}>
      {props.children}
      {search.trim() !== '' && (
        <div
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className={`${
            show ? `show` : `hidden`
          } absolute top-12 z-50 mt-2 w-full border border-gray-200 bg-white sm:top-10 sm:mt-3 sm:max-w-120 lg:mt-4 lg:max-w-88 xl:max-w-120`}
        >
          <div className="absolute -top-2 left-4 -z-10 h-4 w-4 rotate-45 bg-gray-200 shadow-md"></div>

          {!emptyCollectionsResult && show && (
            <section>
              <p className="bg-gray-200 px-4 pb-2 pt-3 text-xs uppercase leading-normal text-gray-700">
                {t`Suggested Products`}
              </p>
              <ul>
                {results == null ? (
                  <Loading />
                ) : (
                  results.collections.map((collection) => {
                    let collectionUrl = `/collections/${collection.handle}`;
                    const missingAccessories =
                      collection.handle?.includes('accessories') &&
                      !collection.title?.toLowerCase()?.includes('accessories');
                    const collectionTitle = missingAccessories
                      ? `${collection.title} Accessories`
                      : collection.title;

                    if (collection.title === 'Gift Cards')
                      collectionUrl = `/${collection.handle}`;

                    return (
                      <Link href={collectionUrl} key={collection.handle}>
                        <a
                          onClick={() => {
                            GTM.genericEvent({
                              event: 'search_collection_result_click',
                              handle: collection.handle,
                            });

                            trackSearchSubmitted();

                            if (props.toggle) props?.toggle();
                          }}
                          className="flex cursor-pointer border-b  border-gray-200 p-2 last:border-b-0 hover:bg-gray-300 sm:items-center"
                        >
                          <div className="w-full text-sm leading-normal sm:text-base">
                            <span>{collectionTitle} </span>
                          </div>
                        </a>
                      </Link>
                    );
                  })
                )}
              </ul>
            </section>
          )}

          {!emptyOffersResult && (
            <section>
              <p className="bg-gray-200 px-4 pb-2 pt-3 text-xs uppercase leading-normal text-gray-700">
                {t`Products`}
              </p>
              <ul id="e2e-searchbar-search-results">
                {results == null ? (
                  <Loading />
                ) : (
                  results.offers.map((offer) => (
                    <Link href={getOfferUri(offer)} key={offer.key}>
                      <a
                        onClick={() => {
                          GTM.genericEvent({
                            event: 'search_offer_result_click',
                            offer,
                          });

                          trackSearchSubmitted();

                          if (props.toggle) props?.toggle();
                        }}
                        data-product-id={offer.productId}
                        data-variant-id={offer.variantId}
                        className="flex cursor-pointer border-b  border-gray-200 p-2 last:border-b-0 hover:bg-gray-300 sm:items-center"
                      >
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                          className="mr-4 mt-1 h-[50px] w-[50px] object-contain sm:mt-0 sm:h-[60px] sm:w-[60px]"
                          src={getSmallShopifyImage(offer.imageSrc)}
                          alt={offer.title}
                        />
                        <div className="w-full text-sm leading-normal sm:text-base">
                          <span>
                            {offer.title.replace(/Oppo/g, 'OPPO')}
                            {shouldShowPrice && (
                              <span className="mt-1 block text-sm font-bold text-gray-700">
                                {offer.price &&
                                  priceFormater.long(Number(offer.price))}
                              </span>
                            )}
                          </span>
                        </div>
                      </a>
                    </Link>
                  ))
                )}
              </ul>
              <div
                onClick={() => {
                  props.toggle?.();

                  return router.push(`/search?query=${search}`);
                }}
              >
                <a
                  className="inline-block w-full border-t border-gray-200 bg-transparent px-4 pb-2 pt-3 text-center text-xs font-bold uppercase leading-normal text-gray-700 duration-200 ease-in-out hover:cursor-pointer hover:bg-gray-200"
                  id="e2e-search-view-all-products"
                >
                  {t`View All Products`}
                </a>
              </div>
            </section>
          )}

          {emptyOffersResult && emptyCollectionsResult && (
            <div className="inline-block w-full bg-gray-200 px-4 pb-2 pt-3 text-center text-xs font-bold uppercase leading-normal text-gray-700">{t`No Product Found`}</div>
          )}
        </div>
      )}
    </div>
  );
}
