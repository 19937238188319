import { useState } from 'react';
import Image from 'next/future/image';
import cn from 'classnames';
import Link from 'next/link';
import { ShopByDepartmentProps } from '../types';
import chevronRightIcon from '@/public/icons/menu/chevron-right.svg';
import LinkItem from '../LinkItem';
import ButtonItem from '../ButtonItem';
import { generateCategoryPageUrl } from '@/components/category/helpers';

const INITIAL_MAX_MENU_ITEMS = 5;

const ShopByDepartment = ({
  history,
  currentParent,
  onClick,
  currentChildren,
  closeMenu,
}: ShopByDepartmentProps) => {
  const [seeAll, setSeeAll] = useState(false);

  const isLevelOne = history.length === 0;

  const generateMenuItems = () => {
    if (isLevelOne) {
      return seeAll
        ? currentChildren
        : currentChildren.slice(0, INITIAL_MAX_MENU_ITEMS);
    }

    return currentChildren;
  };

  const menuItems = generateMenuItems();

  const shouldShowSeeAll =
    currentChildren.length > INITIAL_MAX_MENU_ITEMS && isLevelOne;

  return (
    <section
      className={cn({
        'pt-2': history.length === 0,
        'pt-0': history.length > 0,
      })}
    >
      {history.length > 0 && currentParent != null ? (
        <div className="flex items-center justify-between gap-x-5 pl-5">
          <div className="flex h-10 items-center truncate">
            <h2 className="max-w-[100%] truncate text-base font-semibold">
              {currentParent.name}
            </h2>
          </div>
          <Link
            href={generateCategoryPageUrl(currentParent.name, currentParent.pk)}
          >
            <a
              onClick={closeMenu}
              className="flex h-10 shrink-0 items-center px-5 text-xs text-gray-700 underline transition duration-150 ease-in-out hover:text-link-blue-500"
            >
              See All
            </a>
          </Link>
        </div>
      ) : (
        <h2 className="flex h-10 items-center p-5 text-base font-semibold">
          Shop By Department
        </h2>
      )}

      {menuItems.map((item) => {
        if (item.children.length === 0) {
          return (
            <LinkItem
              key={item.pk}
              title={item.name}
              handle={generateCategoryPageUrl(item.name, item.pk)}
              closeMenu={closeMenu}
            />
          );
        }

        return (
          <ButtonItem
            key={item.pk}
            title={item.name}
            onClick={() => onClick(item)}
            history={history}
            subMenuItem={item.children}
            closeMenu={closeMenu}
          />
        );
      })}

      {shouldShowSeeAll && (
        <button
          className="w-full gap-x-2 py-2.5 px-5 text-left hover:bg-gray-300"
          onClick={() => {
            setSeeAll((prevState) => !prevState);
          }}
        >
          <div className="flex items-center gap-x-2">
            See {seeAll ? 'Less' : 'All'}
            <div className="h-[15px] w-[15px]">
              <Image
                src={chevronRightIcon}
                height={14}
                width={14}
                alt="Expand"
                className={cn(
                  'h-full w-full transition-all duration-150 ease-in-out',
                  {
                    'rotate-90': !seeAll,
                    'rotate-[-90deg]': seeAll,
                  },
                )}
              />
            </div>
          </div>
        </button>
      )}
    </section>
  );
};

export default ShopByDepartment;
