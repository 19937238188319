import Image from 'next/future/image';
import BurgerIcon from '@/public/icons/menu/burger.svg';
import { AllItemsProps } from './types';

const AllItems = ({ setShowMenuSlider }: AllItemsProps) => (
  <li
    // eslint-disable-next-line tailwindcss/no-custom-classname
    className="menu-item relative hidden lg:block"
  >
    <button
      onClick={() =>
        typeof setShowMenuSlider === 'function' && setShowMenuSlider(true)
      }
      className="flex w-full items-center justify-center px-3 py-4 pl-0 pr-10 outline-none hover:[text-shadow:0.5px_0px_0px_#000]"
    >
      <div className="-mt-0.5 mr-2 flex h-[12px] w-[12px] items-center justify-center">
        <span className="h-[12px] w-[12px]">
          <Image
            src={BurgerIcon}
            height={12}
            width={12}
            alt="All Items button"
            className="h-full w-full"
          />
        </span>
      </div>
      <span className="whitespace-nowrap">All Items</span>
    </button>
  </li>
);

export default AllItems;
