import AccessBarIcon from './AccessBarIcon';
import * as Icons from './Icons';

export default function AccessBarAbout() {
  return (
    <AccessBarIcon label="About" href="/about-us" className="hidden lg:flex">
      <Icons.About />
    </AccessBarIcon>
  );
}
