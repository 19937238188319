import cn from 'classnames';
import Image from 'next/future/image';
import Link from 'next/link';
import FireIcon from '@/public/icons/collection/fire.svg';

const Deals = () => {
  const dealsMenuTitle = 'Deals';

  return (
    <li className={cn('menu-item relative')}>
      <Link href="/collections/hot-deals">
        <a className="flex w-full px-3 py-4 pl-0 pr-6 hover:[text-shadow:0.5px_0px_0px_#000]">
          <div className="flex items-center justify-center whitespace-nowrap">
            <span className="mr-1.5">{dealsMenuTitle} </span>
            <div className="-mt-0.5 h-4 w-4">
              <Image priority src={FireIcon} alt="Deal" />
            </div>
          </div>
        </a>
      </Link>
    </li>
  );
};

export default Deals;
