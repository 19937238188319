import { useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import AccessBar from './AccessBar/AccessBar';
import BottomNav from './BottomNav';

const Navbar = () => {
  const { pathname } = useRouter();

  // State Management
  const [showMenuSlider, setShowMenuSlider] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  // Conditions
  const hideBottomNav = useMemo(
    () => pathname === '/checkout/[checkoutId]',
    [pathname],
  );

  return (
    <nav
      ref={containerRef}
      id="header"
      className={cn(
        'sticky top-0 z-50 w-full bg-white pt-4 drop-shadow-sm transition duration-300 lg:bg-white lg:pt-2 lg:drop-shadow-md',
        { 'pb-4 lg:pb-2': hideBottomNav },
      )}
    >
      <AccessBar
        toggle={() => setShowMenuSlider((b) => !b)}
        className={cn(
          'reebelo-container mb-0 grid grid-cols-3 items-center justify-between gap-4 xxs:gap-5 sm:flex sm:grid-cols-none lg:justify-start',
          { 'lg:pb-2': !hideBottomNav },
        )}
        hideMenu={hideBottomNav}
      />

      {!hideBottomNav && (
        <BottomNav
          setShowMenuSlider={setShowMenuSlider}
          containerRef={containerRef}
          showMenuSlider={showMenuSlider}
        />
      )}
    </nav>
  );
};

export default Navbar;
