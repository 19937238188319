import { MainMenuItem } from '@lambda/apis/src/category/types';

export const TRENDING: (MainMenuItem & { handle: string })[] = [
  {
    pk: '02h610t3d104',
    name: 'Smartphones',
    parent: '05v7s7l686k4',
    children: [],
    handle: '/collections/smartphones',
  },
  {
    pk: '05t394s0h4e1',
    name: 'Laptops',
    parent: '05x7j355s2l2',
    children: [],
    handle: '/collections/laptops',
  },
  {
    pk: '05t3a2p3p2b0',
    name: 'Tablets',
    parent: '05x7j2w6e3n3',
    children: [],
    handle: '/collections/tablets',
  },
  {
    pk: '02h610t3d120',
    name: 'Smartwatches',
    parent: '05v7q6s0q393',
    children: [],
    handle: '/collections/smartwatches',
  },
];
