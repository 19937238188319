import SearchBarMobile from './SearchMobile/SearchBarMobile';
import { BottomNavProps } from './types';
import HorizontalMenu from './HorizontalMenu';
import MainMenu from '../MainMenu';

const BottomNav = ({
  setShowMenuSlider,
  containerRef,
  showMenuSlider,
}: BottomNavProps) => (
  <>
    <SearchBarMobile
      className="mt-2"
      toggle={() => setShowMenuSlider(false)}
      containerRef={containerRef}
    />
    <div className="lg:flex lg:border">
      <div className="lg:reebelo-container" id="inline-menu">
        <HorizontalMenu setShowMenuSlider={setShowMenuSlider} />
        <MainMenu showMenu={showMenuSlider} setShowMenu={setShowMenuSlider} />
      </div>
    </div>
  </>
);

export default BottomNav;
