import * as Icons from './Icons';

export default function AccessBarHamburger(props: { toggle?: () => void }) {
  return (
    <button
      className="flex w-8 outline-none xxs:w-auto lg:hidden"
      aria-label="Search bar and menu"
      onClick={() => {
        props.toggle?.();
      }}
    >
      <Icons.Menu />
    </button>
  );
}
