import Link from 'next/link';
import dayjs from 'dayjs';

const dealsName = dayjs().isBefore('2024-01-01', 'day')
  ? 'Holiday Deals'
  : 'Hot Deals';
const listArray = [
  { name: dealsName, handle: 'hot-deals' },
  { name: 'iPhones', handle: 'apple-iphone' },
  { name: 'iPads', handle: 'apple-ipad' },
  { name: 'Laptops', handle: 'laptops' },
  { name: 'Smartwatches', handle: 'smartwatches' },
];

export default function PopularSearches(props: {
  toggle?: () => void;
  onClose: () => void;
}) {
  return (
    <div className="reebelo-container mt-2 rounded-md bg-white sm:hidden">
      <div className="flex h-60 flex-col items-start rounded bg-white pt-4">
        <p className="mb-1 w-full rounded bg-gray-200 px-2 py-1 text-xs leading-normal text-gray-400">
          Popular searches
        </p>
        {listArray.map((collection) => (
          <Link
            href={`/collections/${collection.handle}`}
            key={collection.handle}
          >
            <a
              onClick={() => {
                if (props.toggle) props?.toggle();
                props.onClose();
              }}
              className="flex-1 px-2 py-2 text-sm text-gray-400"
            >
              {collection.name}
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
}
