import AccessBarIcon from './AccessBarIcon';
import * as Icons from './Icons';
import settings from '@/settings';

export default function AccessBarHelp() {
  const href =
    settings.store === 'reebelo-ca' ? settings.externals.help_center : '/help';

  return (
    <AccessBarIcon label="Help" href={href} className="hidden pl-2 lg:flex">
      <Icons.Faq />
    </AccessBarIcon>
  );
}
