import Image from 'next/image';
import { useContext, useState } from 'react';
import { AppCtx } from 'pages/_app';
import settings from '@/settings';
import DeliveryByPopup from './DeliveryByPopup';
import { ZipcodeStateData } from '@/lib/use-customer-zipcode';
import droppedPin from '@/public/images/droppedPin.svg';

export default function AccessBarDeliverTo() {
  const { setZipcodeStateData, zipcodeStateData } = useContext(AppCtx);
  const [isDeliverByOpen, setIsDeliverByOpen] = useState(false);

  const handleStateNameChange = (data: ZipcodeStateData) => {
    setZipcodeStateData(data);
  };

  return (
    <>
      {['reebelo-au', 'reebelo-nz', 'reebelo-ca'].includes(settings.store) && (
        <>
          <DeliveryByPopup
            isOpen={isDeliverByOpen}
            onClose={() => setIsDeliverByOpen(false)}
            onStateNameChange={handleStateNameChange}
          />
          <div
            className="hidden min-w-[200px] cursor-pointer justify-end border-r border-gray-200 pr-5 lg:flex xl:pr-8"
            onClick={() => setIsDeliverByOpen(true)}
          >
            <div className="mb-[5px] flex items-end">
              <div className="relative w-[15px]">
                <Image
                  src={droppedPin}
                  alt="Deliver to"
                  width={10}
                  height={12}
                  className="w-full"
                />
              </div>
            </div>
            <div className="ml-2 text-sm xl:text-base">
              <div className="leading-none text-slate-gray-300">Deliver to</div>

              <div className="text-gray-700">
                {zipcodeStateData?.stateName || '-'}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
