import { generateBuybackUrl } from '@/lib/menu/helpers';
import helpIcon from '@/public/icons/help.svg';
import accountIcon from '@/public/icons/account.svg';
import freeReturnIcon from '@/public/icons/sell-your-device.svg';
import LinkItem from '../LinkItem';
import { SettingsProps } from '../types';
import settings from '@/settings';
import { useCustomerContext } from '@/lib/customer/CustomerContext';

const Settings = ({ closeMenu }: SettingsProps) => {
  const { customer } = useCustomerContext();

  const shouldShowBuyback = settings.buyback_exist;

  return (
    <section className="pt-2">
      <h2 className="flex h-10 items-center p-5 text-base font-semibold">
        Help & Settings
      </h2>
      <div>
        <LinkItem
          title="Help"
          handle="/help"
          icon={helpIcon}
          closeMenu={closeMenu}
        />
        <LinkItem
          title={customer ? 'Account' : 'Sign In'}
          handle={customer ? '/account/orders' : '/account/login'}
          icon={accountIcon}
          closeMenu={closeMenu}
        />
        {shouldShowBuyback && (
          <LinkItem
            title="Sell Your Device"
            handle={generateBuybackUrl()}
            icon={freeReturnIcon}
            closeMenu={closeMenu}
          />
        )}
      </div>
    </section>
  );
};

export default Settings;
