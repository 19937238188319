import dynamic from 'next/dynamic';
import SearchBar from './SearchInput';
import AccessBarAbout from './AccessBarAbout';
import AccessBarHelp from './AccessBarHelp';
import AccessBarCart from './AccessBarCart';
import AccessBarLogo from './AccessBarLogo';
import AccessBarDeliverTo from './AccessBarDeliverTo';
import AccessBarHamburger from './AccessBarHamburger';

const AccessBarAccount = dynamic(() => import('./AccessBarAccount'), {
  ssr: false,
});

export default function AccessBar(props: {
  toggle?: () => void;
  className: string;
  hideMenu: boolean;
}) {
  return (
    <div className={props.className}>
      {props.hideMenu ? (
        <div />
      ) : (
        <AccessBarHamburger toggle={props.toggle}></AccessBarHamburger>
      )}

      <AccessBarLogo />
      {!props.hideMenu && (
        <SearchBar className="mx-4 hidden max-w-24 self-start pt-1 sm:block sm:max-w-120 lg:min-w-[280px] xl:min-w-[400px]" />
      )}
      <div className="ml-auto flex items-end gap-1 justify-self-end xxs:gap-2 xs:gap-3 xl:gap-4">
        <AccessBarDeliverTo />
        <AccessBarHelp />
        <AccessBarAbout />
        <AccessBarAccount />
        <AccessBarCart />
      </div>
    </div>
  );
}
