import { CategoryTree } from '@lambda/apis/src/category/types';
import Loading from '@lambda/ui/src/Loading';
import { ContentProps, SlideDirectionT } from '../types';
import HotDealsAndTrending from './HotDealsAndTrending';
import ShopByDepartment from './ShopByDepartment';
import Settings from './Settings';

const Content = ({
  history,
  currentChildren,
  setCurrentChildren,
  setHistory,
  currentParent,
  setSlideDirection,
  closeMenu,
}: ContentProps) => {
  const onItemClick = (item: CategoryTree) => {
    setSlideDirection(SlideDirectionT.LEFT);
    setCurrentChildren(item.children);
    setHistory((prevState) => [...prevState, item.parent ?? '']);
  };

  return (
    <div
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="hide-scrollbar flex w-full flex-1 flex-col divide-y divide-gray-500 pt-2"
    >
      {/* Static Data - Trending */}
      {history.length === 0 && <HotDealsAndTrending closeMenu={closeMenu} />}
      {/* The menu always has children; if not, it's still loading data. */}
      {currentChildren.length > 0 ? (
        <ShopByDepartment
          history={history}
          currentParent={currentParent}
          currentChildren={currentChildren}
          onClick={onItemClick}
          closeMenu={closeMenu}
        />
      ) : (
        <div className="flex items-center justify-start p-5">
          <Loading />
        </div>
      )}
      {/* Static Data - Help & Settings */}
      {history.length === 0 && <Settings closeMenu={closeMenu} />}
    </div>
  );
};

export default Content;
