import { TRENDING } from '../constants';
import { HotDealsAndTrendingProps } from '../types';
import LinkItem from '../LinkItem';
import HotDeals from './HotDeals';

const HotDealsAndTrending = ({ closeMenu }: HotDealsAndTrendingProps) => (
  <section>
    {/* Hot Deals Banner  */}
    <HotDeals closeMenu={closeMenu} />
    {/* Trending Section */}
    <h2 className="flex h-10 items-center p-5 text-base font-semibold">
      Trending
    </h2>
    {TRENDING.map((item) => (
      <LinkItem
        key={item.pk}
        title={item.name}
        handle={item.handle}
        closeMenu={closeMenu}
      />
    ))}
  </section>
);

export default HotDealsAndTrending;
