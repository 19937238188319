import Image from 'next/future/image';
import Link from 'next/link';
import cn from 'classnames';
import { LinkItemProps } from './types';

const LinkItem = ({
  title,
  handle,
  icon,
  isSubMenu = false,
  closeMenu,
  className,
}: LinkItemProps) => (
  <Link href={handle}>
    <a
      className={cn(
        'block w-full py-2 text-left outline-none hover:bg-gray-300',
        className,
        {
          'px-5': !isSubMenu,
          'pl-10 pr-5': isSubMenu,
        },
      )}
      onClick={closeMenu}
    >
      <div className="flex items-center gap-x-2">
        {icon && (
          <div className="flex h-6 w-6 items-center justify-center">
            <Image
              alt={title}
              src={icon}
              height={30}
              width={30}
              className="h-full w-full"
            />
          </div>
        )}
        {title}
      </div>
    </a>
  </Link>
);

export default LinkItem;
