import Image from 'next/future/image';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import searchIcon from '@/public/icons/menu/search-mobile.svg';

export default function SearchInput({
  setIsOpen,
  containerRef,
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  containerRef: RefObject<HTMLDivElement>;
}) {
  const [showSearchBar, setShowSearchBar] = useState(true);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;
    let delayTimeout: ReturnType<typeof setTimeout> | null = null;

    const updateScrollDir = () => {
      const { scrollY } = window;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;

        return;
      }

      if (scrollY > lastScrollY) {
        setShowSearchBar(false);
      } else if (containerRef.current) {
        setShowSearchBar(
          lastScrollY <= containerRef.current.scrollHeight || scrollY === 0,
        );
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;

        if (delayTimeout) clearTimeout(delayTimeout);

        delayTimeout = window.setTimeout(() => {
          updateScrollDir();
        }, 1000) as unknown as NodeJS.Timeout;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);

      if (delayTimeout) clearTimeout(delayTimeout);
    };
  }, [showSearchBar]);

  return (
    <div
      className={cn(
        'reebelo-container overflow-auto transition-all sm:hidden',
        {
          'max-h-56 duration-300 ease-in': showSearchBar,
          'max-h-0 duration-200 ease-out': !showSearchBar,
        },
      )}
      id="inline-menu"
    >
      <div
        className="relative mt-3 rounded-md shadow-sm"
        onClick={() => setIsOpen(true)}
      >
        <input
          type="text"
          name="account-number"
          id="account-number"
          className="block w-full rounded-md border border-gray-200 py-3 pl-3 pr-10 text-sm text-gray-900 ring-1 ring-inset ring-gray-200"
          placeholder="What are you looking for?..."
        />
        <div className=" pointer-events-none absolute inset-y-0 right-0 flex w-[35px] items-center pr-3">
          <Image
            src={searchIcon}
            alt="search icon"
            width="27"
            height="27"
            sizes="100vw"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
}
