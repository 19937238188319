import settings from '@/settings';
import { BuybackProps } from './types';
import { generateBuybackUrl } from '@/lib/menu/helpers';

const Buyback = ({ view }: BuybackProps) => {
  const endpoint = generateBuybackUrl();

  if (settings.buyback_exist) {
    return (
      <>
        {view === 'mobile' ? (
          <li className="flex shrink-0 items-center pl-3 lg:hidden">
            <a
              className="rounded-full border border-gray-700 px-2 py-1 text-xs hover:[text-shadow:0.5px_0px_0px_#000] lg:text-sm"
              href={endpoint}
            >
              Sell Your Device
            </a>
          </li>
        ) : (
          <div className="ml-3 hidden shrink-0 items-center lg:flex">
            <a
              className="rounded-full border border-gray-700 px-2 py-1 text-xs hover:[text-shadow:0.5px_0px_0px_#000] lg:text-sm"
              href={endpoint}
            >
              Sell Your Device
            </a>
          </div>
        )}
      </>
    );
  }

  return null;
};

export default Buyback;
