// import useSWR from 'swr';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import useSWR from 'swr';
import { CategoryTree } from '@lambda/apis/src/category/types';
import { MainMenuProps, SlideDirectionT } from './types';
import { X } from '@/public/icons/account/icons';
import BackButton from './BackButton';
import ContentWrapper from './Content/ContentWrapper';
import Content from './Content';

const MainMenu: React.FC<MainMenuProps> = ({ showMenu, setShowMenu }) => {
  // Fetch Main Menu Data
  const { data } = useSWR<{
    menu: CategoryTree[];
    flatMenu: Record<string, CategoryTree>;
  }>('/api/menu/main');

  const menu = useMemo(() => data?.menu ?? [], [data]);
  const flatMenu = useMemo(() => data?.flatMenu ?? {}, [data]);

  // State Management
  const [currentChildren, setCurrentChildren] = useState(menu ?? []);
  const [history, setHistory] = useState<string[]>([]);
  const [slideDirection, setSlideDirection] = useState<SlideDirectionT>(
    SlideDirectionT.NONE,
  );

  useEffect(() => {
    if (menu) {
      setCurrentChildren(menu);
      setHistory([]);
    }
  }, [menu]);

  const closeMenuPanel = () => {
    setSlideDirection(SlideDirectionT.NONE);
    setShowMenu(false);

    // setTimeout For a Smooth Leave Animation
    setTimeout(() => {
      setCurrentChildren(menu);
      setHistory([]);
    }, 500);
  };

  const currentParent = useMemo(
    () => flatMenu?.[currentChildren?.[0]?.parent ?? ''] ?? null,
    [currentChildren],
  );

  const previousParent = useMemo(
    () => flatMenu?.[history[history.length - 1] ?? ''],
    [history],
  );

  const previousParentName = useMemo(
    () => flatMenu?.[previousParent?.pk ?? '']?.name,
    [previousParent],
  );

  // Methods
  const onBackToMainMenuClick = () => {
    setSlideDirection(SlideDirectionT.RIGHT);
    setCurrentChildren(menu);
    setHistory([]);
  };

  const onBackToParentClick = () => {
    setSlideDirection(SlideDirectionT.RIGHT);
    setCurrentChildren(flatMenu[previousParent.pk].children);
    setHistory((prevState) => {
      const newHistory = [...prevState];

      newHistory.pop();

      return newHistory;
    });
  };

  return (
    <Transition.Root show={showMenu} as={Fragment}>
      <Dialog
        as="div"
        className="absolute z-50 text-sm"
        onClose={closeMenuPanel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100 transform translate-x-0"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100 transform translate-x-0"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700/50 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-[100vw] lg:pr-20 xl:pr-0">
            <Transition.Child
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              className="flex"
            >
              <Dialog.Panel
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className="hide-scrollbar pointer-events-auto relative h-[100dvh] w-screen overflow-x-hidden overflow-y-scroll bg-white sm:max-w-[75vw] md:max-w-[50vw] lg:max-w-[325px] "
              >
                <Dialog.Title className="sticky top-0 z-10 border-b border-gray-500 bg-white">
                  <div className="flex items-center justify-between">
                    {/* Back Button  */}
                    <div className="flex h-14 flex-1 items-center truncate border-r border-gray-500">
                      <BackButton
                        history={history}
                        onBackToMainMenuClick={onBackToMainMenuClick}
                        onBackToParentClick={onBackToParentClick}
                        parentName={previousParentName}
                      />
                    </div>
                    {/* Close Button */}
                    <button
                      className="h-14 px-5 outline-none transition-all duration-150 ease-in-out hover:scale-110"
                      type="button"
                      onClick={closeMenuPanel}
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                </Dialog.Title>
                {/* Menu Content  */}
                <ContentWrapper
                  history={history}
                  slideDirection={slideDirection}
                >
                  <Content
                    history={history}
                    setHistory={setHistory}
                    currentParent={currentParent}
                    currentChildren={currentChildren}
                    setCurrentChildren={setCurrentChildren}
                    setSlideDirection={setSlideDirection}
                    closeMenu={closeMenuPanel}
                  />
                </ContentWrapper>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MainMenu;
