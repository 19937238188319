import cn from 'classnames';
import { useContext } from 'react';
import { AppCtx } from 'pages/_app';
import { useRouter } from 'next/router';
import AccessBarIcon from './AccessBarIcon';
import * as Icons from './Icons';
import useIsomorphicLayoutEffect from '@/lib/use-iso-layout-effects';
import { initializeCart } from '@/lib/use-cart';

export default function AccessBarCart() {
  const router = useRouter();
  const { cartCount, setCartCount, setCart, setIsLoadingCart } =
    useContext(AppCtx);

  useIsomorphicLayoutEffect(() => {
    if (typeof window === 'undefined' || cartCount == null || cartCount !== 0)
      return;

    initializeCart(setIsLoadingCart).then(({ cart, count }) => {
      setCart(cart);
      setCartCount(count);
    });
  }, [router.route]);

  return (
    <AccessBarIcon label="Cart" href="/cart">
      <Icons.Cart />
      <span
        id="access-bar-count"
        className={cn(
          'absolute top-0 right-0 translate-x-2/4 -translate-y-2/4 rounded-full bg-teal-500 py-1 px-2 text-center text-xs font-bold leading-none text-gray-700 lg:top-1 lg:right-1',
          { hidden: cartCount === 0 },
        )}
      >
        {cartCount}
        <span className="hidden">number of items in cart</span>
      </span>
    </AccessBarIcon>
  );
}
