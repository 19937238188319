import { HorizontalMenuProps } from './types';
import MenuItem from './MenuItem';
import Deals from './MenuItem/Deals';
import AllItems from './MenuItem/AllItems';
import Buyback from './MenuItem/Buyback';
import { HorizontalMenuItem } from '@/settings/types';
import menu from '@/settings/menus';

const HorizontalMenu = ({ setShowMenuSlider }: HorizontalMenuProps) => {
  const menuItems: HorizontalMenuItem[] = menu;

  return (
    <div className="flex items-center">
      <ul
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="main-menu hide-scrollbar flex w-full overflow-x-auto px-5 text-[.8rem] sm:px-8 lg:px-0 lg:text-sm"
      >
        {/* All Items  */}
        <AllItems setShowMenuSlider={setShowMenuSlider} />
        {/* Horizontal Menu Items  */}
        <Deals />
        {menuItems.map((data, index) => {
          const { title, handle, pk } = data;

          return (
            <MenuItem
              key={data.handle ?? data.pk}
              title={title}
              handle={handle}
              pk={pk}
              index={index}
            />
          );
        })}
        <Buyback view="mobile" />
      </ul>
      {/* Sell Your Device - Desktop */}
      <Buyback view="desktop" />
    </div>
  );
};

export default HorizontalMenu;
