// TODO: move all these icon to use next image and priority true
export const Logo = () => (
  <svg
    width="140"
    height="30"
    viewBox="0 0 123 26"
    fill="#1f2323"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.72 7.975.744 13.52a.168.168 0 0 0 .138.195l3.282.578 4.402-2.54a.538.538 0 0 1 .736.196l.288.498.526-2.993a.168.168 0 0 0-.137-.196l-8.063-1.42a.17.17 0 0 0-.196.138Z" />
    <path d="m4.387 21.863 2.887-1.667 1.738-4.777a.54.54 0 0 1 .691-.323l.54.196-.224-.389-.663-1.15-.632-1.094a.172.172 0 0 0-.23-.061l-7.09 4.1a.172.172 0 0 0-.062.23L4.156 21.8c.049.082.152.11.23.062ZM25.928 7.975a.17.17 0 0 0-.196-.137l-3.282.577-3.265 3.897a.54.54 0 0 1-.76.066l-.44-.371.08.44.23 1.309.22 1.244a.17.17 0 0 0 .195.137l8.063-1.423a.17.17 0 0 0 .137-.195l-.982-5.544Z" />
    <path d="M11.714 17.94a.536.536 0 0 1 .323-.69l.54-.197-2.856-1.037a.172.172 0 0 0-.216.1l-1.29 3.535-.374 1.025-1.14 3.13a.172.172 0 0 0 .099.217l5.289 1.924a.172.172 0 0 0 .216-.1l1.142-3.13-1.733-4.777Z" />
    <path d="m19.803 20.678-4.402-2.54a.535.535 0 0 1-.196-.735l.289-.499-2.856 1.038a.17.17 0 0 0-.1.217l2.801 7.694a.17.17 0 0 0 .217.1l5.289-1.925a.167.167 0 0 0 .1-.216l-1.142-3.134Z" />
    <path d="m26.244 16.695-2.887-1.667-5.004.884a.541.541 0 0 1-.626-.437l-.1-.567-1.518 2.633a.166.166 0 0 0 .062.23l7.09 4.093a.17.17 0 0 0 .23-.062l2.814-4.877a.171.171 0 0 0-.061-.23ZM18.723 11.514a.169.169 0 0 0 .237-.021l2.42-2.883.7-.835 2.141-2.554a.168.168 0 0 0-.02-.237l-4.313-3.619a.168.168 0 0 0-.237.021l-2.142 2.553v5.083c0 .3-.24.54-.54.54h-.576l2.33 1.952ZM10.577 8.658a.54.54 0 0 1 .437.625l-.1.567 2.327-1.952a.169.169 0 0 0 .02-.237L8 1.386a.168.168 0 0 0-.237-.02L3.449 4.983a.168.168 0 0 0-.02.237l2.14 2.554 5.008.883Z" />
    <path d="m13.824 7.239.285.34a.54.54 0 0 1-.065.76l-.44.37H16.642a.169.169 0 0 0 .168-.168V.352a.169.169 0 0 0-.168-.168h-5.633a.169.169 0 0 0-.168.168v3.333l2.983 3.554ZM44.517 6.549l-.886 1.86c-.059-.028-.688-.544-1.946-.544-1.945 0-3.546 1.773-3.546 4.488v7.863h-2.172V6.206h1.828l.316 1.858c.657-1.171 2-2.257 3.832-2.257 1.63-.004 2.46.684 2.574.742ZM51.494 20.613c-4.289 0-6.976-3.344-6.976-7.406 0-4.032 2.945-7.406 6.949-7.406 3.918.027 6.574 2.773 6.574 7.062v.945H46.662c.114 2.773 2.031 4.945 4.832 4.945 2.23 0 3.774-1.285 4.23-2.715l1.915.602c-.711 2.055-2.77 3.973-6.145 3.973Zm4.516-8.606c-.172-2.43-1.973-4.344-4.516-4.344-2.773 0-4.46 1.973-4.804 4.344h9.32ZM66.42 20.613c-4.288 0-6.976-3.344-6.976-7.406 0-4.032 2.945-7.406 6.95-7.406 3.917.027 6.574 2.773 6.574 7.062v.945h-11.38c.114 2.773 2.032 4.945 4.833 4.945 2.23 0 3.773-1.285 4.23-2.715l1.915.602c-.715 2.055-2.774 3.973-6.145 3.973Zm4.517-8.606c-.172-2.43-1.973-4.344-4.516-4.344-2.774 0-4.461 1.973-4.805 4.344h9.32ZM76.483.203v8.035c1.03-1.371 2.629-2.43 4.917-2.43 4.489 0 6.66 3.746 6.66 7.375 0 3.602-2.429 7.406-6.66 7.406-2.457 0-4.058-1.144-4.976-2.43l-.285 2.059H74.31V.203h2.171Zm4.69 18.44c2.802 0 4.72-2.315 4.72-5.46 0-3.172-1.915-5.433-4.72-5.433-2.831 0-4.718 2.289-4.718 5.433 0 3.145 1.887 5.46 4.719 5.46ZM96.47 20.613c-4.288 0-6.976-3.344-6.976-7.406 0-4.032 2.945-7.406 6.95-7.406 3.917.027 6.574 2.773 6.574 7.062v.945h-11.38c.114 2.773 2.032 4.945 4.833 4.945 2.23 0 3.773-1.285 4.23-2.715l1.915.602c-.715 2.055-2.774 3.973-6.145 3.973Zm4.517-8.606c-.172-2.43-1.973-4.344-4.516-4.344-2.774 0-4.461 1.973-4.805 4.344h9.321ZM104.419.203h2.172v20.011h-2.172V.204ZM115.142 20.613c-4.601 0-7.148-3.746-7.148-7.406 0-3.688 2.66-7.406 7.148-7.406 4.574 0 7.203 3.715 7.203 7.406 0 3.66-2.629 7.405-7.203 7.405Zm0-1.973c2.973 0 5.032-2.371 5.032-5.433 0-3.086-2.059-5.434-5.032-5.434-2.945 0-4.976 2.344-4.976 5.434 0 3.058 2.031 5.433 4.976 5.433Z" />
  </svg>
);

export const LogoMobile = () => (
  <svg width="100" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.058 7.445 9.034 9.593c-.068-.031-.795-.627-2.248-.627-2.247 0-4.097 2.049-4.097 5.185v9.084H.179V7.048h2.113l.365 2.148c.758-1.354 2.31-2.608 4.427-2.608 1.882-.004 2.843.79 2.974.857ZM18.116 23.698c-4.954 0-8.06-3.864-8.06-8.556 0-4.657 3.403-8.556 8.029-8.556 4.526.032 7.595 3.204 7.595 8.159v1.091H12.534c.131 3.205 2.347 5.713 5.582 5.713 2.577 0 4.36-1.484 4.888-3.136l2.211.695c-.821 2.374-3.2 4.59-7.099 4.59Zm5.218-9.942c-.199-2.807-2.28-5.018-5.218-5.018-3.204 0-5.153 2.279-5.55 5.018h10.768ZM35.364 23.698c-4.955 0-8.06-3.864-8.06-8.556 0-4.657 3.403-8.556 8.029-8.556 4.526.032 7.595 3.204 7.595 8.159v1.091H29.782c.131 3.205 2.347 5.713 5.582 5.713 2.577 0 4.36-1.484 4.888-3.136l2.212.695c-.826 2.374-3.205 4.59-7.1 4.59Zm5.218-9.942c-.199-2.807-2.28-5.018-5.218-5.018-3.204 0-5.153 2.279-5.55 5.018H40.58ZM46.992.115v9.283c1.19-1.585 3.037-2.807 5.681-2.807 5.185 0 7.695 4.327 7.695 8.52 0 4.16-2.807 8.555-7.695 8.555-2.839 0-4.689-1.322-5.749-2.806l-.33 2.378h-2.112V.115h2.51Zm5.42 21.304c3.235 0 5.45-2.676 5.45-6.308 0-3.665-2.211-6.277-5.45-6.277-3.272 0-5.452 2.644-5.452 6.277 0 3.632 2.18 6.308 5.451 6.308ZM70.08 23.698c-4.955 0-8.06-3.864-8.06-8.556 0-4.657 3.403-8.556 8.029-8.556 4.526.032 7.595 3.204 7.595 8.159v1.091H64.498c.131 3.205 2.346 5.713 5.582 5.713 2.577 0 4.36-1.484 4.888-3.136l2.211.695c-.826 2.374-3.204 4.59-7.099 4.59Zm5.217-9.942c-.198-2.807-2.279-5.018-5.217-5.018-3.204 0-5.153 2.279-5.55 5.018h10.767ZM79.265.115h2.51v23.119h-2.51V.115ZM91.648 23.698c-5.316 0-8.257-4.328-8.257-8.556 0-4.26 3.072-8.556 8.257-8.556 5.285 0 8.322 4.292 8.322 8.556 0 4.228-3.037 8.556-8.322 8.556Zm0-2.28c3.435 0 5.814-2.739 5.814-6.276 0-3.566-2.379-6.277-5.814-6.277-3.401 0-5.748 2.707-5.748 6.277 0 3.533 2.347 6.277 5.748 6.277Z"
      fill="#1F2323"
    />
  </svg>
);

export const Menu = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M1 1L16 1M1 8H16M1 15C1 15 8.18951 15 16 15"
      stroke="#1F2323"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export const Faq = () => (
  <svg
    width="30"
    height="30"
    viewBox="-3 -2 30 25"
    fill="#1f2323"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.1615 20C9.19216 20 6.49235 18.3201 5.16508 15.6845C2.2933 15.3943 0 12.9825 0 10.0238C0 7.06746 2.29069 4.65359 5.1647 4.36304C6.50214 1.69848 9.20261 0 12.1615 0C15.1204 0 17.8209 1.69848 19.1583 4.36304C22.0297 4.65335 24.323 7.06504 24.323 10.0238C24.323 12.9802 22.0322 15.3941 19.158 15.6845C17.8307 18.3201 15.1309 20 12.1615 20ZM12.1615 1.42518C9.62442 1.42518 7.31739 2.95867 6.28413 5.33192L6.10252 5.74907L5.64765 5.75986C3.31938 5.81501 1.42518 7.72779 1.42518 10.0238C1.42518 12.3197 3.31938 14.2325 5.64765 14.2876L6.10252 14.2984L6.28413 14.7156C7.30485 17.06 9.61188 18.5748 12.1615 18.5748C14.7112 18.5748 17.0182 17.06 18.0389 14.7156L18.2205 14.2984L18.6754 14.2876C21.0037 14.2325 22.8979 12.3197 22.8979 10.0238C22.8979 7.72779 21.0037 5.81501 18.6754 5.75986L18.2205 5.74907L18.0389 5.33192C17.0057 2.95867 14.6986 1.42518 12.1615 1.42518Z"></path>
    <path d="M12.161 14.7165C12.669 14.7165 13.0808 14.3047 13.0808 13.7967C13.0808 13.2888 12.669 12.877 12.161 12.877C11.653 12.877 11.2412 13.2888 11.2412 13.7967C11.2412 14.3047 11.653 14.7165 12.161 14.7165Z"></path>
    <path d="M12.1617 5.31445C10.6402 5.31445 9.40234 6.53701 9.40234 8.03972C9.40234 8.41602 9.71118 8.72104 10.0922 8.72104C10.4732 8.72104 10.782 8.41602 10.782 8.03972C10.782 7.28837 11.4009 6.67709 12.1617 6.67709C12.9224 6.67709 13.5413 7.28837 13.5413 8.03972C13.5413 8.79108 12.9224 9.40236 12.1617 9.40236C11.7807 9.40236 11.4718 9.70739 11.4718 10.0837V11.787C11.4718 12.1633 11.7807 12.4683 12.1617 12.4683C12.5427 12.4683 12.8515 12.1633 12.8515 11.787V10.6788C14.0403 10.3755 14.921 9.30728 14.921 8.03972C14.921 6.53701 13.6832 5.31445 12.1617 5.31445Z"></path>
  </svg>
);

export const Account = () => (
  <svg
    width="30"
    height="30"
    viewBox="-3 -3 30 30"
    fill="#1f2323"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.3046 0.583984C5.35074 0.583984 0.511353 5.42337 0.511353 11.3772C0.511353 14.8511 2.16799 17.9385 4.72322 19.9114C4.78848 19.9817 4.86379 20.0419 4.95415 20.0821C6.73629 21.3873 8.93007 22.1654 11.3046 22.1654C13.6791 22.1654 15.8729 21.3873 17.655 20.0821C17.7404 20.0419 17.8207 19.9817 17.8859 19.9114C20.4412 17.9335 22.0978 14.8511 22.0978 11.3772C22.0978 5.42337 17.2584 0.583984 11.3046 0.583984ZM11.3046 20.6644C9.35678 20.6644 7.54954 20.057 6.05355 19.0279C6.24934 16.8542 8.07665 15.1423 10.3006 15.1423H12.3086C14.5325 15.1423 16.3649 16.8542 16.5556 19.0279C15.0596 20.057 13.2524 20.6644 11.3046 20.6644ZM7.5395 9.87118C7.5395 7.79286 9.22626 6.1061 11.3046 6.1061C13.3829 6.1061 15.0697 7.79286 15.0697 9.87118C15.0697 11.9495 13.3829 13.6363 11.3046 13.6363C9.22626 13.6363 7.5395 11.9495 7.5395 9.87118ZM17.8809 17.9285C17.4141 16.1714 16.144 14.7457 14.4823 14.068C15.7524 13.1041 16.5757 11.583 16.5757 9.8762C16.5757 6.96956 14.2112 4.60509 11.3046 4.60509C8.39794 4.60509 6.03347 6.96454 6.03347 9.87118C6.03347 11.583 6.85677 13.1041 8.12686 14.063C6.47022 14.7407 5.19511 16.1714 4.72824 17.9234C3.05153 16.2467 2.01738 13.9325 2.01738 11.3772C2.01738 6.25671 6.18407 2.09002 11.3046 2.09002C16.4251 2.09002 20.5918 6.25671 20.5918 11.3772C20.5918 13.9325 19.5576 16.2467 17.8809 17.9285Z"></path>
  </svg>
);

export const Cart = () => (
  <svg
    width="30"
    height="30"
    viewBox="-3 -5 30 30"
    fill="#1f2323"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.1721 11.9776C19.7309 11.9776 19.3552 11.6401 19.3156 11.1925C19.2734 10.7191 19.6237 10.3012 20.0972 10.2594H20.098C20.1557 9.78772 20.5818 9.45502 21.0574 9.51011C21.5295 9.56822 21.8648 9.99775 21.8067 10.4695C21.7081 11.2704 21.0527 11.9031 20.2487 11.9741C20.2229 11.9767 20.197 11.9776 20.1721 11.9776Z"></path>
    <path d="M6.18945 13.1952C5.74786 13.1952 5.37213 12.8573 5.33296 12.4093C5.29121 11.9354 5.64199 11.5184 6.11542 11.4766L20.096 10.2534C20.572 10.2095 20.9869 10.5625 21.0282 11.0359C21.07 11.5098 20.7192 11.9268 20.2457 11.9686L6.2652 13.1918C6.24024 13.1939 6.21441 13.1952 6.18945 13.1952Z"></path>
    <path d="M20.9543 11.2199C20.919 11.2199 20.8837 11.2177 20.848 11.2134C20.3758 11.1553 20.0405 10.7258 20.0986 10.2541L21.1432 1.77399C21.2018 1.30227 21.6343 0.966995 22.1026 1.0251C22.5747 1.0832 22.91 1.51274 22.8519 1.98445L21.8073 10.4645C21.7539 10.9005 21.3825 11.2199 20.9543 11.2199Z"></path>
    <path d="M3.89831 4.32418C3.45673 4.32418 3.08099 3.98632 3.04182 3.53828C3.00008 3.06441 3.35085 2.64736 3.82428 2.60561L21.9216 1.02218C22.3967 0.978711 22.8125 1.3312 22.8538 1.80464C22.8956 2.27851 22.5448 2.69556 22.0713 2.73731L3.97406 4.32073C3.94867 4.32289 3.92327 4.32418 3.89831 4.32418Z"></path>
    <path d="M3.67379 2.64472C3.24942 2.64472 2.87971 2.33053 2.82203 1.89798V1.89712C2.34688 1.89712 1.96124 1.51192 1.96124 1.03633C1.96124 0.560741 2.34688 0.175537 2.82203 0.175537C3.6811 0.175537 4.4145 0.817688 4.52855 1.66944C4.59139 2.14072 4.26042 2.5737 3.78913 2.63697C3.74997 2.64214 3.71209 2.64472 3.67379 2.64472Z"></path>
    <path d="M2.82179 1.89712H1.33735C0.862197 1.89712 0.476562 1.51149 0.476562 1.03633C0.476562 0.561172 0.862197 0.175537 1.33735 0.175537H2.82179C3.29695 0.175537 3.68258 0.561172 3.68258 1.03633C3.68258 1.51149 3.29738 1.89712 2.82179 1.89712Z"></path>
    <path d="M4.87596 11.6649C4.45159 11.6649 4.08145 11.3507 4.02421 10.9177L2.82125 1.89876C2.75841 1.42748 3.08939 0.994499 3.56067 0.931661C4.02894 0.867962 4.46536 1.1998 4.52777 1.67108L5.7303 10.6905C5.79314 11.1617 5.46216 11.5947 4.99088 11.6576C4.95214 11.6627 4.91427 11.6649 4.87596 11.6649Z"></path>
    <path d="M8.7477 19.974C7.11477 19.974 5.73492 18.5942 5.73492 16.9613C5.73492 15.3283 7.11477 13.9485 8.7477 13.9485C10.3806 13.9485 11.7605 15.3283 11.7605 16.9613C11.7605 18.5942 10.3806 19.974 8.7477 19.974ZM8.7477 15.6701C8.05992 15.6701 7.45651 16.2735 7.45651 16.9613C7.45651 17.649 8.05992 18.2524 8.7477 18.2524C9.43547 18.2524 10.0389 17.649 10.0389 16.9613C10.0389 16.2735 9.43504 15.6701 8.7477 15.6701Z"></path>
    <path d="M16.9251 19.974C15.2921 19.974 13.9123 18.5942 13.9123 16.9613C13.9123 15.3283 15.2921 13.9485 16.9251 13.9485C18.558 13.9485 19.9378 15.3283 19.9378 16.9613C19.9378 18.5942 18.558 19.974 16.9251 19.974ZM16.9251 15.6701C16.2373 15.6701 15.6339 16.2735 15.6339 16.9613C15.6339 17.649 16.2373 18.2524 16.9251 18.2524C17.6128 18.2524 18.2163 17.649 18.2163 16.9613C18.2163 16.2735 17.6124 15.6701 16.9251 15.6701Z"></path>
    <path d="M6.57994 13.159C5.29693 13.159 4.19684 12.2129 4.02425 10.9175C3.96141 10.4462 4.29281 10.0132 4.7641 9.95079C5.23194 9.88666 5.66836 10.2194 5.73077 10.6906C5.79188 11.1481 6.20679 11.4705 6.6587 11.4344C7.1429 11.393 7.55005 11.7438 7.59137 12.2168C7.63312 12.6903 7.28235 13.1077 6.80934 13.1495C6.7323 13.1555 6.65526 13.159 6.57994 13.159Z"></path>
  </svg>
);

export const About = () => (
  <svg
    width="30"
    height="30"
    viewBox="-4 -4 30 30"
    fill="#1f2323"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M10.3541 0C4.63146 0 0 4.63085 0 10.3541C0 16.0767 4.63085 20.7081 10.3541 20.7081C16.0767 20.7081 20.7081 16.0773 20.7081 10.3541C20.7081 4.63146 16.0773 0 10.3541 0ZM10.3541 19.2634C5.44146 19.2634 1.44476 15.2667 1.44476 10.3541C1.44476 5.44146 5.44146 1.44476 10.3541 1.44476C15.2667 1.44476 19.2634 5.44146 19.2634 10.3541C19.2634 15.2667 15.2667 19.2634 10.3541 19.2634Z"></path>
      <path d="M10.3532 8.23438C9.95426 8.23438 9.63086 8.55782 9.63086 8.95673V14.8802C9.63086 15.2792 9.9543 15.6026 10.3532 15.6026C10.7521 15.6026 11.0756 15.2791 11.0756 14.8802V8.95677C11.0756 8.55782 10.7522 8.23438 10.3532 8.23438Z"></path>
      <path d="M10.3532 5.10547C9.95426 5.10547 9.63086 5.42891 9.63086 5.82783V6.69469C9.63086 7.09365 9.9543 7.41705 10.3532 7.41705C10.7521 7.41705 11.0756 7.09361 11.0756 6.69469V5.82783C11.0756 5.42891 10.7522 5.10547 10.3532 5.10547Z"></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20.7081" height="20.7081"></rect>
      </clipPath>
    </defs>
  </svg>
);
