import Image from 'next/future/image';
import Link from 'next/link';
import { HotDealsProps } from '../types';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

const HotDeals = ({ closeMenu }: HotDealsProps) => (
  <div className="px-5 py-2.5">
    <Link href="/collections/hot-deals">
      <a onClick={closeMenu} className="group">
        <div
          className="flex items-center justify-between gap-x-5 rounded-lg px-3 py-1.5" // eslint-disable-next-line react/forbid-dom-props
          style={{
            background:
              'linear-gradient(90deg, rgba(123,53,53,1) 0%, rgba(31,35,35,1) 40%, rgba(31,35,35,1) 100%)',
          }}
        >
          <div className="h-14">
            <Image
              src={getEdgeUrl('images/hot-deals/menu.png')}
              alt="Hot Deals"
              height={100}
              width={200}
              className="h-full w-auto rounded-tl-xl object-cover object-right"
            />
          </div>
          <div className="shrink-0 rounded-full bg-red px-4 py-2 text-xs font-semibold text-white group-hover:underline">
            Browse Deals
          </div>
        </div>
      </a>
    </Link>
  </div>
);

export default HotDeals;
