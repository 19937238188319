import settings from '@/settings';

export const generateBuybackUrl = () => {
  switch (settings.store) {
    case 'quista':
      return '/buyback/sell-electronic';
    case 'reebelo-us':
      return '/buyback';
    case 'reebelo-dev':
      return '/buyback';
    default:
      return '/sell-phone';
  }
};
