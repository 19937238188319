import { Transition } from '@headlessui/react';
import { ContentWrapperProps, SlideDirectionT } from '../types';

const ContentWrapper = ({
  history,
  slideDirection,
  children,
}: ContentWrapperProps) => {
  if (!children) return null;

  // Animation is not triggered when opening and closing the Main Menu
  if (slideDirection === SlideDirectionT.NONE) return <div>{children}</div>;

  // Animation is triggered when navigating through menu items
  return (
    <Transition.Child
      key={history.length}
      enter="transform transition ease-in-out duration-500"
      enterFrom={
        slideDirection === SlideDirectionT.RIGHT
          ? '-translate-x-full'
          : 'translate-x-full'
      }
      enterTo={
        slideDirection === SlideDirectionT.RIGHT
          ? 'translate-x-0'
          : '-translate-x-0'
      }
      leave="transform transition ease-in-out duration-500"
      leaveFrom={
        slideDirection === SlideDirectionT.RIGHT
          ? 'translate-x-0'
          : '-translate-x-full'
      }
      leaveTo={
        slideDirection === SlideDirectionT.RIGHT
          ? '-translate-x-full'
          : 'translate-x-0'
      }
      as="div"
    >
      {children}
    </Transition.Child>
  );
};

export default ContentWrapper;
