import Link from 'next/link';
import { PropsWithChildren } from 'react';
import cn from 'classnames';
import i18n from '@/settings/i18n';

const t = i18n.init();

export default function AccessBarIcon(
  props: PropsWithChildren<{
    label: string;
    href?: string;
    className?: string;
    onClick?: () => void;
  }>,
) {
  const className = cn(
    props.className,
    'decoration-none relative flex w-8 flex-col items-center text-sm sm:w-10  lg:w-12',
  );

  const content = (
    <>
      <span className="h-[26px] w-[26px] xxs:h-[28px] xxs:w-[28px]">
        {props.children}
      </span>
      <span className="mt-1 hidden text-sm lg:block">{t(props.label)}</span>
    </>
  );

  return (
    <>
      {props.href ? (
        <Link href={props.href} prefetch={false}>
          <a
            className={className}
            id={`e2e-header-${props.label.replace(/\s+/g, '-').toLowerCase()}`}
            aria-label={`${props.label} Link`}
          >
            {content}
          </a>
        </Link>
      ) : (
        <button
          onClick={props.onClick}
          className={className}
          id={`e2e-header-${props.label.replace(/\s+/g, '-').toLowerCase()}`}
          aria-label={`${props.label} button`}
        >
          {content}
        </button>
      )}
    </>
  );
}
