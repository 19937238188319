import Image from 'next/future/image';
import { useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { categoryNameToHandle } from '@lambda/reebelo/src/commonHelpers';
import chevronRightIcon from '@/public/icons/menu/chevron-right.svg';
import { ButtonItemProps } from './types';
import LinkItem from './LinkItem';

const ButtonItem = ({
  title,
  onClick,
  history,
  subMenuItem,
  closeMenu,
}: ButtonItemProps) => {
  const [showLeafLevel, setShowLeafLevel] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const shouldShowDropdown = useMemo(() => history.length === 2, [history]);

  return (
    <>
      <button
        className="flex w-full items-center justify-between py-2 px-5 text-left outline-none transition duration-150 ease-in-out hover:bg-gray-300"
        onClick={() => {
          if (shouldShowDropdown) setShowLeafLevel((prevState) => !prevState);
          else onClick();
        }}
      >
        <div className="flex items-center gap-x-2.5">{title}</div>
        <div className="h-[15px] w-[15px]">
          <Image
            src={chevronRightIcon}
            height={14}
            width={14}
            alt="Expand"
            className={cn(
              'h-full w-full transition-all duration-150 ease-in-out',
              {
                'rotate-90': shouldShowDropdown && !showLeafLevel,
                '-rotate-90': shouldShowDropdown && showLeafLevel,
              },
            )}
          />
        </div>
      </button>

      <div
        ref={contentRef}
        // eslint-disable-next-line react/forbid-dom-props
        style={{
          maxHeight:
            shouldShowDropdown && showLeafLevel
              ? `${contentRef.current?.scrollHeight ?? 2500}px`
              : '0px',
          transition: 'max-height 0.3s ease, opacity 0.3s ease',
        }}
        className="overflow-hidden"
      >
        {subMenuItem.map((item) => (
          <LinkItem
            title={item.name}
            key={item.name}
            handle={`/collections/${categoryNameToHandle(item.name)}/${
              item.pk
            }`}
            isSubMenu
            closeMenu={closeMenu}
          />
        ))}
      </div>
    </>
  );
};

export default ButtonItem;
